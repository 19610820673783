<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" >
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Plan <span>Rename</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <Form @invalid-submit="handleFormInvalidSubmit" @submit="handleSubmitForm" v-slot="{ errors }" ref="rename-workout-plan-form">
                            <div class="group_item">
                                <label class="input_label">Plan Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="Plan 1" rules="required" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapActions } from 'vuex'

    export default {
        name: 'Rename Workout Plan',

        data () {
            return {
                form: {
                    name: '',
                    id: '',
                },
                loader: false,
            }
        },

        props: {
            modelValue: Boolean,
            workoutPlan: Object,
            refreshWorkoutPlan: {
                type: Function,
                default: () => {}
            }
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue (value) {
                const vm = this;

                vm.toggleScrollBar(value);

                if (value) {
                    vm.form = { name: vm.workoutPlan.name, id: vm.workoutPlan.id };
                }
            }
        },

        methods: {
            ...mapActions({
                renameWorkoutPlan: 'workoutModule/renameWorkoutPlan'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const renameForm = vm.$refs['rename-workout-plan-form'];

                renameForm.validate().then((result) => {
                    if (result.valid) {
                        vm.handleRenameWorkoutPlan(renameForm);
                    }
                });
            },

            handleRenameWorkoutPlan (form) {
                const vm = this;

                vm.loader             = true
                vm.form.setFieldError = form.setFieldError;

                vm.renameWorkoutPlan(vm.form).then((result) => {
                    vm.loader = false;

                    if (result) {
                        vm.refreshWorkoutPlan();
                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>
